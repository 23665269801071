<template>
	<section class="content-main" style="position: relative">
		<el-tabs v-model="activeNameS" type="card" @tab-click="handleClick">
			<el-tab-pane label="等待注册" name="1"></el-tab-pane>
			<el-tab-pane label="等待解析" name="2"></el-tab-pane>
			<el-tab-pane label="解析成功" name="3"></el-tab-pane>
			<el-tab-pane label="开通成功" name="4"></el-tab-pane>
			<el-tab-pane label="续费提醒" name="5"></el-tab-pane>
		</el-tabs>
		<el-col :span="24" class="content-wrapper">
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</el-col>
	</section>
</template>

<script>
    export default{
        data(){
            return {
                activeNameS: '1'
            };
        },
        methods: {
            //切换页面
            handleClick(tab, event) {
                switch(this.activeNameS){
					case '1':
                        this.$router.push({ name: '等待注册', query: { type: '1' }})
						break;
                    case '2':
                        this.$router.push({ name: '等待解析', query: { type: '2' }})
                        break;
                    case '3':
                        this.$router.push({ name: '解析成功', query: { type: '3' }})
                        break;
                    case '4':
                        this.$router.push({ name: '开通成功', query: { type: '4' }})
                        break;
                    case '5':
                        this.$router.push({ name: '续费提醒', query: { type: '5' }})
                        break;
				}
            }
        },
        mounted() {
            //当前所在状态页
            this.activeNameS = this.$route.query.type || '1'
        },
        updated: function () {
            //当前所在状态页
            this.activeNameS = this.$route.query.type || '1'
        }
    }
</script>

<style lang="scss">
	.date-type{
		position: absolute;
		right: 0;
		top: 0;
		z-index: 9;
		.el-select {
			width: 100px;
		}
	}
</style>